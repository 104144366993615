import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { IKeyValuePair } from 'src/app/models/common';
import { AlertService } from 'src/app/services/alert.service';

@Component({
  selector: 'app-super-select',
  templateUrl: './super-select.component.html',
  styleUrls: ['./super-select.component.scss'],
})
export class SuperSelectComponent implements OnInit, OnChanges {
  @Input() selectOptions: Array<IKeyValuePair> = [];
  @Input() keyOnlySelectedOptions: Array<string> = [];
  @Input() selectedOptions: Array<IKeyValuePair> = [];
  @Input() model: any;
  @Output() modelChange = new EventEmitter();
  @Output() optionSelectedEvent = new EventEmitter();

  isMultiple = true;
  filterKey: string | any;
  filteredOptions: Array<IKeyValuePair> | any;
  inputValue: any;

  constructor(
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.filteredOptions = this.selectOptions;
    // this.filterKey = '';
    if (this.keyOnlySelectedOptions && this.keyOnlySelectedOptions.length > 0) {
      this.selectedOptions = this.keyOnlySelectedOptions.map(el => ({
        key: el,
        value: el,
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectOptions'] && changes['selectOptions'].currentValue) {
      this.selectOptions = changes['selectOptions'].currentValue;
      this.ngOnInit();
    }

    if (
      changes['keyOnlySelectedOptions'] &&
      changes['keyOnlySelectedOptions'].currentValue
    ) {
      this.selectedOptions = changes['keyOnlySelectedOptions'].currentValue.map(
        (el: any) => ({
          key: el,
          value: el,
        })
      );
    }
  }

  itemSelected(option: IKeyValuePair) {
    if (!this.isMultiple) {
      this.optionSelectedEvent.emit(option);
      this.selectedOptions = [];
      this.selectedOptions.push(option);
    } else {
      if (this.selectedOptions) {
        const element = this.selectedOptions.find(
          el => el.key.toString() === option.key.toString()
        );
        if (!element) {
          this.selectedOptions.push(option);
        }
      } else {
        this.selectedOptions = [];
        this.selectedOptions.push(option);
      }
      this.optionSelectedEvent.emit(this.selectedOptions);
    }
  }

  filterOptions(e: any) {
    this.inputValue = e;
    const filter = new RegExp(e, 'gi');
    this.filteredOptions = this.selectOptions.filter(
      el => el.key.toString().match(filter) || el.value.toString().match(filter)
    );
  }

  itemUnselected(option: any) {
    if (!this.isMultiple) {
      this.optionSelectedEvent.emit(null);
      this.selectedOptions = [];
    } else {
      this.selectedOptions = this.selectedOptions.filter(
        el => el.key.toString() !== option.key.toString()
      );
      this.optionSelectedEvent.emit(this.selectedOptions);
    }
  }

  clearAll() {
    if (!this.isMultiple) {
      this.optionSelectedEvent.emit(null);
      this.selectedOptions = [];
    } else {
      this.selectedOptions = [];
      this.optionSelectedEvent.emit(this.selectedOptions);
    }
  }

  addTag() {
    if (!this.inputValue) this.alertService.warning('Enter the tag to add.');
    else {

    }
  }
}
