import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-edit-textarea',
  templateUrl: './edit-textarea.component.html',
  styleUrls: ['./edit-textarea.component.scss'],
})
export class EditTextareaComponent implements OnInit {
  @Input() data: string = '';
  @Output() focusOut: EventEmitter<string> = new EventEmitter<string>();
  @Input() editMode = false;

  constructor() {}

  ngOnInit(): void {}

  onFocusOut() {
    this.focusOut.emit(this.data);
  }
}
