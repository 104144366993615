import {
    Directive,
    ElementRef,
    AfterViewInit,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[appAvatarImage]',
})
export class AvatarImageDirective implements AfterViewInit, OnChanges {
    constructor(private element: ElementRef) {}

    ngAfterViewInit(): void {
        const img = this.element.nativeElement;

        const origSrc =
            img.getAttribute('src') !== 'null' &&
            img.getAttribute('src') !== 'undefined';
        // console.log(img.getAttribute('src'), origSrc);
        if (!origSrc) {
            const name = img.getAttribute('alt');
            const initialsImage = this.letterAvatar(
                name,
                img.getAttribute('width')
            );
            img.setAttribute('src', initialsImage);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
    }

    letterAvatar = (name: string, size: any) => {
        name = name || '';
        size = size || 60;

        // tslint:disable-next-line:one-variable-per-declaration
        let nameSplit = String(name).toUpperCase().split(' '),
            initials,
            charIndex,
            canvas,
            context,
            dataURI;

        if (nameSplit.length === 1) {
            initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
        } else {
            initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
        }

        if (window.devicePixelRatio) {
            size = size * window.devicePixelRatio;
        }

        charIndex = (initials === '?' ? 72 : initials.charCodeAt(0)) - 64;

        canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        context = canvas.getContext('2d');
        if (context) {
            context.fillStyle = '#858796'; // colours[colourIndex - 1];
            context.fillRect(0, 0, canvas.width, canvas.height);
            context.font = Math.round(canvas.width / 2) + 'px Arial';
            context.textAlign = 'center';
            context.fillStyle = '#FFF';
            context.fillText(initials, size / 2, size / 1.5);
        }

        dataURI = canvas.toDataURL();
        canvas = null;

        return dataURI;
    };
}
