<div class="top-right-alert" *ngIf="message">
  <div
    class="text-center alert-class alert-dismissible bounceInRight"
    [ngClass]="{
      alert: message,
      'alert-success': message.type === 'success',
      'alert-warning': message.type === 'warning',
      'alert-danger': message.type === 'error',
      bounceOutRight: dismissAlert
    }"
  >
    {{ message.text }}
    <button type="button" class="close" (click)="clear()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
