export const environment = {
  production: false,
  //API_BASE_URL: '',
  //API_BASE_URL: 'http://localhost:7000/api',
  API_BASE_URL: 'https://api.instarise.co/api',
  smmPanelURL: 'https://smmfollows.com/api/v2',
  smmPanelKey: '2289162994c2ae4e97f9281a11eceb7c',
  n1PanelURL: 'https://n1panel.com/api/v2',
  n1PanelKey: '99978a620736adcc218a8ba07c130e13'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
