<nav class="navbar navbar-expand-lg bg-secondary navbar-dark bg-nlrc-menu fixed-top py-3">
  <div class="container-fluid">
    <a class="navbar-brand" href="#" [routerLink]="['/']">
      <img src="assets/images/logo.png" width="200">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#topNavbar" aria-controls="topNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="topNavbar">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle fw-bold" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           {{ currentUser.firstName }} {{ currentUser.lasstName }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            <li><a class="dropdown-item" href="#" [routerLink]="['/user/settings']" routerLinkActive="active">Settings</a></li>
            <li><hr class="dropdown-divider"></li>
            <li><a class="dropdown-item" href="javascript:void(0)" (click)="logoutUser()">Logout</a></li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
