
import { AuthGuard } from '../guards/auth.guard';
import { DashboardComponent } from '../features/dashboard/dashboard.component';
import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'categories-management',
  //   component: ReportsComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'notifications',
  //   component: NotificationsComponent,
  //   canActivate: [AuthGuard],
  // },
  // { path: 'login', redirectTo: '/auth/login', pathMatch: 'full' },
  // { path: 'forgot-password', redirectTo: '/auth/forgot-password' },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('../features/dashboard/dashboard.module').then(m => m.DashboardModule),
  // },Quotation
  {
    path: 'categories-management',
    loadChildren: () =>
      import('../features/categories/categories.module').then(m => m.CategoriesModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'services-management',
    loadChildren: () =>
      import('../features/services/services.module').then(m => m.ServicesModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'packages-management',
    loadChildren: () =>
      import('../features/packages/packages.module').then(m => m.PackagesModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('../features/orders/orders.module').then(m => m.OrdersModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'users',
    loadChildren: () =>
      import('../features/user/user.module').then(m => m.UserModule),
      canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('../features/auth/auth.module').then(m => m.AuthModule),
  },

];
