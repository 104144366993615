import { filter } from 'rxjs';
import {
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TablePageConfig } from './table.model';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent implements OnInit, OnChanges {
  @Input() tablePage!: TablePageConfig;
  @Output() loadPageIndex = new EventEmitter<number>();
  @Input() tablePageData: any = [];
  @Input() tablePageIndex = 0;
  @Input() tablePageSize = 10;
  @Input() tablePagesLength = 0;
  @Input() tableDataLoading = false;

  @Input() filter!: string;

  hkeys!: string[];

  data!: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  renderTable = () => {
    this.data = new MatTableDataSource(this.tablePageData);
  };

  ngOnInit(): void {
    this.hkeys = this.tablePage.columns.map(x => x.key);
    this.data.filterPredicate = this.filterBySubject();
  }

  applyFilter(value: string) {
    this.data.filter = value.trim().toUpperCase();
  }
  filterBySubject() {
    let filterFunction =
        (data: any, filter: string): boolean => {
          if (filter) {
            const subjects = data.subjects;
            for (let i = 0; i < subjects.length; i++) {
              if (subjects[i].indexOf(filter) != -1) {
                return true;
              }
            }
            return false;
          } else {
            return true;
          }
       };
    return filterFunction;
}

  ngOnChanges(changes: SimpleChanges): void {

    if (changes['tablePageData'] && changes['tablePageData'].currentValue) {
      this.renderTable();
    }
    if (changes['tablePageIndex'] && !changes['tablePageIndex'].firstChange) {
      this.paginator.pageIndex = changes['tablePageIndex'].currentValue;
      this.renderTable();
    }
    if(changes['filter']) {
      this.applyFilter(this.filter)
    }
  }

  handlePageChange = (ev: any) => {
    this.loadPageIndex.emit(ev.pageIndex);
  };
}
