import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface IKeyValuePairLC {
  key: string;
  value: string;
  lic?: string[];
  isDefault?: boolean;
  order?: number;
  isDisabled?: boolean;
  hide?: boolean;
}

interface ISelectableOption extends IKeyValuePairLC {
  ticked?: boolean;
  isDisabled?: boolean;
}

@Component({
  selector: 'table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['table-settings.component.scss'],
})
export class TableSettingsComponent implements OnInit {
  @Input() selectableOptions!: IKeyValuePairLC[];
  @Input() model!: string[];
  @Output() modelChange = new EventEmitter<string[]>();
  @Output() closeClicked = new EventEmitter<boolean>();

  public showModal = false;
  public elementSelector = '#tableSettingsModal';
  public initalStateOptions!: ISelectableOption[];
  public initialStateModel!: string[];
  public allOptions: ISelectableOption[] = [];
  public selectedOptions: IKeyValuePairLC[] = [];
  public displayOptions = false;
  public inputLabel = { labelFilter: '' };
  public vMinSearchLength = 0;

  ngOnInit(): void {
    this.manageOptions();
  }

  manageOptions = () => {
    this.showModal = true;
    this.initalStateOptions = this.selectableOptions.map(el => {
      if (this.model.indexOf(el.key) === -1) {
        return {
          ...el,
          ticked: false,
        };
      } else {
        return {
          ...el,
          ticked: true,
          order: this.model.indexOf(el.key),
        };
      }
    });
    this.selectedOptions.push(...this.sortByOrder(this.initalStateOptions));
    this.allOptions.push(...this.initalStateOptions.filter(x => !x.ticked));
    this.sortOptions();
  };

  resetFilterModel = () => {
    this.allOptions = [...this.initalStateOptions];
  };

  sortByOrder(value: any[]): IKeyValuePairLC[] {
    return this.initalStateOptions
      .filter(x => x.ticked)
      .sort((a: any, b: any) => a.order - b.order);
  }

  sortOptions = () => {
    this.allOptions.sort((a: any, b: any) => a.value - b.value);
    this.allOptions.sort(
      (a: any, b: any) => Number(a.isDisabled) - Number(b.isDisabled)
    );
  };

  searchChanged = (): void => {
    if (
      this.inputLabel.labelFilter.length < this.vMinSearchLength &&
      this.inputLabel.labelFilter.length > 0
    ) {
      return;
    }
    const filter = new RegExp(this.inputLabel.labelFilter, 'gi');
    this.allOptions = this.initalStateOptions.filter(
      el =>
        !el.ticked &&
        (String(el.key).match(filter) || String(el.value).match(filter))
    );
  };

  unSyncItems = (item: ISelectableOption) => {
    this.selectedOptions.splice(this.selectedOptions.indexOf(item), 1);
    this.allOptions.push(item);
    this.sortOptions();
  };

  syncItems = (item: ISelectableOption) => {
    if (item.isDisabled) {
      return;
    }
    this.allOptions.splice(this.allOptions.indexOf(item), 1);
    this.model.push(item.key);
    this.selectedOptions.push(item);
    this.sortOptions();
  };

  drop = (event: any) => {
    moveItemInArray(
      this.selectedOptions,
      event.previousIndex,
      event.currentIndex
    );
  };

  handleSaveChanges = () => {
    this.modelChange.emit(this.selectedOptions.map(el => el.key));
    this.hideModal();
  };

  hideModal = () => {
    this.showModal = false;
    this.closeClicked.emit(true);
  };
}
