import { Component } from '@angular/core';
import { IUserProfile } from './models/accounts';
import { AuthService } from './services/auth.service';
import { IApiResponse } from './models/common';
import { HttpErrorResponse } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showBackground = false;
  shoNavbar = false;
  currentUser!: IUserProfile;
  loadingUser = false;
  showFooter = false;

  public preAuthRoutes = [
    'login',
    'forgot-password',
    'reset-password',
    'email-verification',
  ];

  public noNavbarRoutes = [...this.preAuthRoutes, 'welcome'];

  corderLinksProg: any = [];

  publishedCC: any = [];

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe(e => {
        this.showBackground = Boolean(
          this.preAuthRoutes.find(x => this.router.url.search(x) >= 0)
        );
        this.shoNavbar = !Boolean(
          this.noNavbarRoutes.find(x => this.router.url.search(x) >= 0)
        );
        if (!this.showBackground) {
          this.currentUser =  this.authService.getLocalDetails();
        }
      });
  }



  logoutUser = () => {
    this.authService.logoutAndRedirect();
  };

  getPublishedCustomCampaign = () => {
    if (this.currentUser?.role === 'EPS_TEAM') {
      return;
    }
  };
}
