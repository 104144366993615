import {
  Directive,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';

declare const $: any;

@Directive({
  selector: '[appImageCropper]',
})
export class ImageCropperDirective {
  @Output() cropppedImage = new EventEmitter<any>();
  @Input() cropAspectRatio: number | any;
  @Input() modalName: string | undefined;

  constructor(private element: ElementRef) {}

  cropper: any;

  ngAfterViewInit(): void {
    const el = this.element.nativeElement;
    const image: any = document.getElementById('croppableImage');

    const $modal = $(`#cropModal`);
    el.addEventListener('change', function (e: any) {
      const files = e.target.files;
      const done = function (url: any) {
        // console.log(url);
        image.setAttribute('src', url); //   src = url;
        $modal.modal('show');

        $modal.insertAfter($('body'));
      };
      let reader: any;
      let file;

      if (files && files.length > 0) {
        file = files[0];
        if (URL) {
          done(URL.createObjectURL(file));
        } else if (FileReader) {
          reader = new FileReader();
          reader.onload = function (e: any) {
            done(reader.result);
          };
          reader.readAsDataURL(file);
        }
      }
    });

    $modal
      .on('shown.bs.modal', () => {
        console.log('shown');
        // this.cropper = new Cropper(image, {
        //   aspectRatio: 1, // this.cropAspectRatio || 0.95,
        //   viewMode: 2,
        // });
      })
      .on('hidden.bs.modal', () => {
        this.cropper.destroy();
        this.cropper = null;
      });

    document?.getElementById('crop')?.addEventListener('click', () => {
      let canvas;
      $modal.modal('hide');
      if (this.cropper) {
        canvas = this.cropper.getCroppedCanvas({
          width: 320,
          height: 320,
        });
        canvas.toBlob((blob: any) => {
          this.cropppedImage.emit(blob);
        });
      }
    });
  }
}
