import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { IUser, IUserProfile } from '../models/accounts';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  login = (username: string, password: string): Observable<any> =>
    this.http.post(`${environment.API_BASE_URL}/auth/login-admin`, {
      email: username,
      password,
    });


  getWebsocketTicket = () =>
    this.http.get(`${environment.API_BASE_URL}/auth/socket-ticket`);

  logout = () => {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
  };

  logoutAndRedirect = () => {
    this.logout();
    // this.getMyDetails().subscribe({
    //   error: err => {
    //     this.router.navigate(['/auth/login']);
    //   },
    //   complete: () => {
    this.router.navigate(['/auth/login']);
    //   },
    // });
  };

  getMyDetails = (): Observable<any> =>
    this.http.get(environment.API_BASE_URL + '/user/me');

  getLocalDetails = (): any => {
    let currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    return currentUser
  };

  // refreshLocalDetails = (user: IUserProfile) => {
  //   localStorage.setItem('firstName', user.firstName);
  //   localStorage.setItem('lastName', user.lastName);
  //   localStorage.setItem('id', user._id);
  //   localStorage.setItem('email', user.email);
  //   // localStorage.setItem('phone', user.phone);
  //   localStorage.setItem('name', `${user.firstName} ${user.lastName}`);
  //   localStorage.setItem('role', user.role);
  // };
}
