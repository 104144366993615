<div class="row">
  <div class="col-md-4 mt-2">
    <input
      name="filterKey"
      [ngModel]="filterKey"
      (ngModelChange)="filterOptions($event)"
      autocomplete="off"
      class="form-control mr-sm-2"
      type="text"
      placeholder="Start Typing .. "
    />
  </div>
  <div class="col-md-2 p-0">
    <div class="btn btn-sm btn-primary mt-3" (click)="addTag()">Add Tag</div>
  </div>
  <div class="col-md-6 mt-2 option-box">
    <button
      (click)="clearAll()"
      *ngIf="selectedOptions && selectedOptions.length > 0"
      class="btn btn-sm btn-outline-danger pull-right rounded"
    >
      <i class="fa fa-close"></i>
    </button>
    <span
      class="rounded btn btn-sm btn-primary mt-1 mr-1"
      *ngFor="let option of selectedOptions"
      (click)="itemUnselected(option)"
      >{{ option.value }} <i class="fa fa-close"></i>
    </span>
  </div>
</div>
<div class="row">
  <div class="col-md-12 mt-3 border padding-10 option-box">
    <button
      type="button"
      class="rounded btn btn-sm btn-outline-primary mt-1 mr-1"
      *ngFor="let option of filteredOptions"
      (click)="itemSelected(option)"
    >
      {{ option.value }}
    </button>
  </div>
</div>
