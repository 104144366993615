import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClimitPipe } from './climit/climit.pipe';

@NgModule({
  declarations: [ClimitPipe],
  imports: [CommonModule],
  exports: [ClimitPipe]
})
export class PipesModule {}
