
import {
    Component,
    OnInit,
    Input,
    AfterViewInit,
    EventEmitter,
    Output,
    ViewChild,
} from '@angular/core';

@Component({
    selector: 'app-google-address',
    templateUrl: './google-address.component.html',
    styleUrls: ['./google-address.component.scss'],
})
export class GoogleAddressComponent implements OnInit, AfterViewInit {
    @Input() labelText!: string;
    @Output() setAddress: EventEmitter<any> = new EventEmitter();
    @ViewChild('addresstext') addresstext: any;

    autocompleteInput!: string;
    queryWait!: boolean;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.getPlaceAutocomplete();
    }

    private getPlaceAutocomplete() {
        // const autocomplete = new google.maps.places.Autocomplete(
        //     this.addresstext.nativeElement,
        //     {
        //         componentRestrictions: { country: ['US', 'CA'] },
        //         types: ['address'], // 'establishment' / 'address' / 'geocode'
        //     }
        // );
        // google.maps.event.addListener(autocomplete, 'place_changed', () => {
        //     const place = autocomplete.getPlace();
        //     this.invokeEvent(place);
        // });
    }

    invokeEvent(place: any) {
        this.setAddress.emit(place);
    }
}
