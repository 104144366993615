import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { AlertComponent } from './alert/alert.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { BackgroundComponent } from './bg-component/background.component';
import { DirectiveModule } from '../directives/directive.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from '../pipes/pipes.module';
import { UsDatePickerComponent } from './us-date-picker/us-date-picker.component';
import { GoogleAddressComponent } from './google-address/google-address.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { EditInputComponent } from './edit-input/edit-input.component';
import { EditTextareaComponent } from './edit-textarea/edit-textarea.component';
import { SuperSelectComponent } from './super-select/super-select.component';
import { TableSettingsComponent } from './table-settings/table-settings.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    AlertComponent,
    ProgressIndicatorComponent,
    BackgroundComponent,
    NavbarComponent,
    FooterComponent,
    UsDatePickerComponent,
    GoogleAddressComponent,
    CustomTableComponent,
    EditInputComponent,
    EditTextareaComponent,
    SuperSelectComponent,
    TableSettingsComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    DirectiveModule,
    PipesModule,
    NgbModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    ProgressIndicatorComponent,
    FormsModule,
    AlertComponent,
    BackgroundComponent,
    UsDatePickerComponent,
    GoogleAddressComponent,
    CustomTableComponent,
    EditInputComponent,
    EditTextareaComponent,
    SuperSelectComponent,
  ],
})
export class ComponentsModule {}
