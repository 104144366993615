import { formatDate } from '@angular/common';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-us-date-picker',
  templateUrl: './us-date-picker.component.html',
  styleUrls: ['./us-date-picker.component.scss'],
})
export class UsDatePickerComponent implements OnChanges {
  @Input() label!: string;
  @Input() date!: string;
  @Output() dateChanged = new EventEmitter<string>();

  model!: Date;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['date'] && changes['date'].currentValue) {
      const d = changes['date'].currentValue.split('/');
      this.model = new Date(
        parseInt(d[2], 10),
        parseInt(d[0], 10) - 1,
        parseInt(d[1], 10)
      );
    }
  }

  events: string[] = [];

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    if (event.value) {
      const v = formatDate(event.value, 'MM/dd/yyyy', 'en');
      this.dateChanged.emit(v);
    }
  }
}
