<!-- <div class="container-x"> -->
<div class="progress">
    <div class="progress-track"></div>

    <div
        id="step1"
        *ngFor="let step of indicatorSteps"
        class="progress-step {{ step.stepClass }}"
    >
        {{ step.stepDesc }}
    </div>
</div>
<!-- </div> -->
