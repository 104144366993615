import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  @Input() extraClazz = '';
  test: Date = new Date();
  constructor() {}
}
