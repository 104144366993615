<div
  class="modal fade animated slideInDown in"
  id="tableSettingsModal"
  [ngClass]="{
    'display-block': showModal
  }"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="block_title custom_block_title">Edit Table</div>
        <div class="row">
          <div class="col-md-7 all-columns-container">
            <div class="form-group">
              <input
                placeholder="Search"
                type="text"
                (keyup)="searchChanged()"
                [(ngModel)]="inputLabel.labelFilter"
                class="form-control"
              />
              <p *ngIf="!allOptions?.length" class="help-block text-danger">
                No columns matching search
              </p>
            </div>
            <div
              [ngClass]="{
                'mouse-pointer selected-column-box': !item.isDisabled,
                'mouse-not-allowed selected-column-box': item.isDisabled
              }"
              *ngFor="let item of allOptions"
              (click)="syncItems(item)"
            >
              <button
                mat-icon-button
                color="primary"
                type="button"
                [ngClass]="
                  !item.isDisabled ? 'mouse-pointer' : 'mouse-not-allowed'
                "
                [disabled]="item.isDisabled"
              >
                <i *ngIf="item.isDisabled" class="fa fa-check"></i>
                <i *ngIf="!item.isDisabled" class="fa fa-times"></i>
              </button>
              {{ item.value }}
            </div>
          </div>
          <div class="col-md-5 all-columns-container">
            <h5>Selected Columns</h5>
            <div
              cdkDropList
              class="selected-column-list"
              (cdkDropListDropped)="drop($event)"
            >
              <div
                class="selected-column-box"
                *ngFor="let item of selectedOptions"
                cdkDrag
              >
                <button
                  mat-icon-button
                  color="warn"
                  type="button"
                  (click)="unSyncItems(item)"
                >
                  <i class="fa fa-close"></i>
                </button>
                {{ item.value }}
                <i class="fa fa-sort"></i>
              </div>
              <p class="help-block" *ngIf="selectedOptions.length">
                You can not remove all columns. Please select at least 1.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer text-left">
        <button mat-button (click)="hideModal()" type="button" color="primary">
          Close
        </button>
        <button
          mat-raised-button
          color="primary"
          id="tableSettingsSaveButton"
          class="ml-1"
          type="button"
          (click)="handleSaveChanges()"
          [disabled]="!selectedOptions.length"
        >
          Save Changes
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade in"></div>
