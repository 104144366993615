import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { IUserProfile } from 'src/app/models/accounts';
import { IApiResponse } from 'src/app/models/common';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnChanges {
  @Input() currentUser!: IUserProfile;
  newNotification = false;
  showCourses = true;
  navbarBrandLogo: any;
  notificationCount = 0;
  allowCreatingTeamUsers = false;
  publishedCC: any = [];

  orderLinksProd: any = [];
  orderLinksProg: any = [];

  //corderLinksProg: any = [];

  constructor(
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    // this.getPublishedCustomCampaign();

    this.getNotificationCount();
  }

  getNotificationCount = () => {
    // this.notificationService.getCount().subscribe((resp: IApiResponse) => {
    //   if (resp.success) {
    //     this.notificationCount = resp.data;
    //     this.newNotification = resp.data !== 0;
    //   }
    // });
  };
  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['currentUser'] && changes['currentUser'].currentValue) {
      if (
        this.currentUser?.role &&
        (this.currentUser?.role.toLowerCase() === 'superadmin' ||
          this.currentUser?.role.toLowerCase() === 'asm')
      ) {
        this.allowCreatingTeamUsers = true;
      }
    }
  }
  logoutUser = () => {
    this.authService.logoutAndRedirect();
  };
}
