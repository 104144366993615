import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'climit'
})
export class ClimitPipe implements PipeTransform {
  transform(
    value: string,
    limit = 25,
    completeWords = false,
    ellipsis = '...'
  ) {
    if (value) {
      if (value.length <= limit) {
        return value;
      }
      if (completeWords) {
        limit = value.substr(0, limit).lastIndexOf(' ');
      }
      return `${value.substr(0, limit)} ${ellipsis}`;
    }
    return '';
  }
}

// usage -- {{longStr | climit : 12 : false : '...' }}
