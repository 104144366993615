<app-bg-component *ngIf="showBackground"></app-bg-component>
<app-navbar *ngIf="shoNavbar" [currentUser]="currentUser"></app-navbar>

<mat-sidenav-container class="insta-container" [ngStyle]="{'background': !shoNavbar?'none':''}">
  <mat-sidenav
  *ngIf="shoNavbar"
    #sidebar
    mode="side"
    class="custom-sidenav"
    position="start"
    [fixedInViewport]="true"
  >
    <nav id="sidebarMenu" class="sidebar">
      <div class="sidebar-sticky pt-5">
        <button
          class="navbar-toggler text-white sidnav-button"
          type="button"
          (click)="sidebar.toggle()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            class="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/dashboard']"
              routerLinkActive="active"
            >
              Dashboard
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/categories-management']"
              routerLinkActive="active"
            >
              Categories
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/services-management']"
              routerLinkActive="active"
            >
              Services
            </a>
          </li>
          <!-- <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/packages-management']"
              routerLinkActive="active"
            >
              Packages
            </a>
          </li> -->
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/orders']"
              routerLinkActive="active"
            >
              Orders
            </a>
          </li>
          <li
            class="nav-item"
          >
            <a
              class="nav-link"
              [routerLink]="['/users']"
              routerLinkActive="active"
            >
              Users
            </a>
          </li>

          <!-- <li
            class="nav-item dropdown"
          >
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
              Sample Request Form
            </a>
            <div class="collapse" id="collapseExample">
              <ul class="nav flex-column ps-3">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/sample-requests/analytical/list']" routerLinkActive="active">Analytical</a>
                  <a class="nav-link" [routerLink]="['/sample-requests/mml/list']" routerLinkActive="active">MML</a>
                </li>
              </ul>
            </div>
          </li>

          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/srf-logbook']"
              routerLinkActive="active"
            >
              SRF Logbook
            </a>
          </li>

          <li
            class="nav-item dropdown"
          >
            <a class="nav-link collapsed" data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
              Manage Masters
            </a>
            <div class="collapse" id="collapseExample2">
              <ul class="nav flex-column ps-3">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/masters/companies']" routerLinkActive="active">All Companies</a>
                  <a class="nav-link" [routerLink]="['/masters/employee-type']" routerLinkActive="active">Employee Type</a>
                  <a class="nav-link" [routerLink]="['/masters/designation']" routerLinkActive="active">Designation</a>
                  <a class="nav-link" [routerLink]="['/masters/product-name']" routerLinkActive="active">Product Name</a>
                  <a class="nav-link" [routerLink]="['/masters/standardization']" routerLinkActive="active">Standardization</a>
                  <a class="nav-link" [routerLink]="['/masters/test-name']" routerLinkActive="active">Test Name</a>
                  <a class="nav-link" [routerLink]="['/masters/stamp']" routerLinkActive="active">Stamp</a>
                  <a class="nav-link" [routerLink]="['/masters/signature']" routerLinkActive="active">Signature</a>
                  <a class="nav-link" [routerLink]="['/masters/status']" routerLinkActive="active">Status</a>
                  <a class="nav-link" [routerLink]="['/masters/fixed-attribute']" routerLinkActive="active">Fixed Attribute</a>
                </li>
              </ul>
            </div>
          </li>

          <li
            class="nav-item"
            *ngIf="currentUser?.role === 'ASM' || currentUser?.role === 'ASMU'"
          >
            <a
              class="nav-link"
              [routerLink]="['/user/system-settings']"
              routerLinkActive="active"
            >
              System Settings
            </a>
          </li> -->
        </ul>
      </div>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <div [ngClass]="{'padded-top': shoNavbar, 'pt-3': !shoNavbar}">
      <router-outlet></router-outlet>
      <app-alert></app-alert>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
