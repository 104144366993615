import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SmmPanelService {

  baseUrl: string;


  constructor(private http: HttpClient) {
    //this.baseUrl = environment.smmPanelURL;
    this.baseUrl = environment.API_BASE_URL + '/smm';
  }

  post = (data: any): Observable<any> =>
  this.http.post(`${this.baseUrl}/add-order`, data);

  getStatus = (data: any): Observable<any> =>
  this.http.post(`${this.baseUrl}/get-order-status`, data);

  getBalance = (data: any): Observable<any> =>
  this.http.post(`${this.baseUrl}/check-balance`, data);

}
