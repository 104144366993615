import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IUser } from 'src/app/models/accounts';
import { AuthService } from 'src/app/services/auth.service';
import { ROLES } from 'src/app/constants';
import { SmmPanelService } from 'src/app/services/smm-panel.service';
import { environment } from 'src/environments/environment';
import { TablePageConfig } from 'src/app/components/custom-table/table.model';
import { IApiResponse } from 'src/app/models/common';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  tc: TablePageConfig = {
    columns: [
      {
        key: 'orderId',
        value: 'Order ID',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'name',
        value: 'Order Name',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'serviceID',
        value: 'Service ID',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'link',
        value: 'Link',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'quantity',
        value: 'Quantity',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'totalPrice',
        value: 'Total Price',
        sortable: false,
        contentType: 'text',
      },
      {
        key: 'orderStatus',
        value: 'Status',
        sortable: false,
        contentType: 'text',
      }
    ],
    sortBy: 'orderNumber',
    sortDir: 'DESC',
    squery: '',
    pageIndex: 0,

    pageSize: 10,
    totalCount: 1,
    data: [],
    loadingComplete: false,
    handlePageChange: () => {},
    handleSortChange: () => {},
  };
  loading = true;
  searchKey = '';

  showLoading = false;

  currentUser!: IUser;


  wallet: any;
  orders: any;

  constructor(
    private orderService: OrderService,
    private smmPanelService: SmmPanelService
    ) {}

  ngOnInit(): void {

    this.checkBalance();
    this.getOrders();

    // setTimeout(() => {
    //   this.currentUser = this.authService.getLocalDetails();

    //   if (
    //     [
    //       ROLES['ACCOUNTING'],
    //       ROLES['ASM'],
    //       'SUPERADMIN',
    //       'SUPER-ADMIN',
    //       'superadmin',
    //       ROLES['ASMU'],
    //       ROLES['SALES_MANAGER'],
    //     ].indexOf(this.currentUser.role) !== -1
    //   ) {
    //     this.showSalesManagerOptions = true;
    //   }

    //   this.showLoading = false;
    // }, 1000);


  }

  mapData = (data: any): any => {

    data.serviceID = '#'+data.orderDetails.serviceCode;
    data.link = data.orderDetails.link;
    data.quantity = data.orderDetails.quantity;
    data.totalPrice = (+data.orderDetails.totalPrice).toFixed(2);

    return {
      ...data
    };
  };

  checkBalance() {
    //let param = `key=${this.n1PanelKey}&action=balance`
    let data = {
      key: '',
      action: 'balance'
    }
    this.smmPanelService.getBalance(data).subscribe(
      (res: any) => {
        if(res.success) {
          this.wallet = res.data;
          this.wallet.balance = Number(this.wallet.balance).toFixed(5);
        }
      },
      (error: Error) => {

      }
    )
  }

  getOrders= () => {
    //this.loading = true;
    this.orderService.getAll().subscribe(
      (response: IApiResponse) => {
        if (response.success) {

          this.tc.data = response.data.map(this.mapData).sort((a: any, b: any) => Number(new Date(b.dateCreated)) - Number(new Date(a.dateCreated)) ).slice(0,5);
          this.orders = this.tc.data;
        }
        this.loading = false;
      },
      (      error: any) => {
        console.log(error);
        this.loading = false;
      }
    );
  };

  handlePageIndexChange = (value: number) => {
    this.tc.pageIndex = value;
    // this.loadCampaigns();
  };
}
