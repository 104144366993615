import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';

interface IIndicatorStep {
    step: number;
    stepClass: string;
    stepDesc?: string;
}

@Component({
    selector: 'app-progress-indicator',
    templateUrl: './progress-indicator.component.html',
    styleUrls: ['./progress-indicator.component.scss'],
})
export class ProgressIndicatorComponent implements OnChanges {
    @Input() step!: number;
    @Input() stepsArray!: string[];

    previousStep!: number;

    stepOneClazz!: string;
    stepTwoClazz!: string;
    stepThreeClazz!: string;
    stepFourClazz!: string;

    indicatorSteps!: IIndicatorStep[];

    constructor() {}

    init = () => {
        this.indicatorSteps = this.stepsArray.map((el, idx) => ({
            step: idx + 1,
            stepDesc: el,
            stepClass: '',
        }));
        this.runForwards();
    };

    runForwards = () => {
        this.indicatorSteps = this.indicatorSteps.map((el, idx) => {
            if (idx + 1 === this.step) {
                return {
                    ...el,
                    stepClass: 'is-active',
                };
            } else if (idx + 1 === this.step - 1) {
                return {
                    ...el,
                    stepClass: 'is-complete',
                };
            } else if (idx + 1 < this.step - 1) {
                return {
                    ...el,
                    stepClass: 'completed',
                };
            } else {
                return { ...el, stepClass: '' };
            }
        });
    };

    runBackwards = () => {
        this.indicatorSteps = this.indicatorSteps.map((el, idx) => {
            if (idx + 1 === this.step) {
                return {
                    ...el,
                    stepClass: 'is-previous-active',
                };
            } else if (idx + 1 === this.step - 1) {
                return {
                    ...el,
                    stepClass: 'completed',
                };
            } else if (idx + 1 < this.step - 1) {
                return {
                    ...el,
                    stepClass: 'completed',
                };
            } else {
                return { ...el, stepClass: '' };
            }
        });
    };

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes['step'] && changes['step'].firstChange) {
            this.init();
        } else {
            if (
                changes &&
                changes['step'] &&
                changes['step'].currentValue < changes['step'].previousValue
            ) {
                this.runBackwards();
            } else {
                this.runForwards();
            }
        }
    }
}
