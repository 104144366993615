<!-- Footer -->
<!-- <footer class="sticky-footer bg-white">
  <div class="container my-auto">
    <div class="copyright text-center my-auto">
      <span>Copyright &copy; Epek Software Solutions 2019</span>
    </div>
  </div>
</footer> -->
<!-- End of Footer -->
<footer class="footer {{ extraClazz }}">
  <div class="row align-items-center justify-content-xl-between">
    <div class="col-xl-6">
      <div class="copyright text-center text-xl-left text-muted">
        &copy; {{ test | date: 'yyyy' }}
        <a
          href="http://epektech.com"
          class="font-weight-bold ml-1"
          target="_blank"
          >Epek Software Solutions</a
        >
      </div>
    </div>
    <div class="col-xl-6">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <a href="mailto:support@epekdata.net" class="nav-link">Support</a>
        </li>
      </ul>
    </div>
  </div>
</footer>
