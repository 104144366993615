<div class="form-group">
  <label class="control-label"
    >{{ label }} <sup class="text-danger">*</sup></label
  >
  <div class="input-group">
    <!-- <input
            class="form-control"
            placeholder="mm/dd/yyyy"
            name="dp"
            [(ngModel)]="model"
            ngbDatepicker
            #d="ngbDatepicker"
            (dateSelect)="handleDateSelected($event)"
        />

        <div class="input-group-append">
            <button
                class="btn btn-outline-secondary"
                (click)="d.toggle()"
                type="button"
            >
                <i class="fa fa-calendar"></i>
            </button>
        </div> -->

    <!-- <mat-form-field > -->
    <input
      class="form-control"
      matInput
      [matDatepicker]="picker"
      placeholder="mm/dd/yyyy"
      (dateInput)="addEvent('input', $event)"
      (dateChange)="addEvent('change', $event)"
      [(ngModel)]="model"
    />
    <mat-datepicker-toggle
      class="input-group-append"
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <!-- </mat-form-field> -->
  </div>
</div>
