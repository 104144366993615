import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../../services/alert.service';
import Swal from 'sweetalert2';


@Component({
    selector: 'app-alert',
    templateUrl: './alert.component.html',
    styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
    message: any;

    dismissAlert = false;
    constructor(private alertService: AlertService, private router: Router) {}

    subs!: Observable<any>;
    ngOnInit(): void {
        this.subs = this.alertService.getMessage();
        this.subs.subscribe(message => {
            if (message) {
                if (message.alertType && message.alertType === 'sval') {
                    let config: any;
                    if (message.type === 'error') {
                        config = {
                            title: 'Error!',
                            text: message.text,
                            icon: 'error',
                            confirmButtonText: 'OK',
                        };
                    }
                    if (message.type === 'success') {
                        config = {
                            title: 'Success!',
                            text: message.text,
                            icon: 'success',
                            confirmButtonText: 'OK',
                        };
                    }
                    if (config) {
                        if (message.redirectionOnConfirm) {
                            Swal.fire(config).then(result => {
                                if (result.value) {
                                    this.router.navigate(
                                        message.redirectionOnConfirm
                                    );
                                }
                            });
                        } else {
                           Swal.fire(config);
                        }
                    }
                } else {
                    this.message = message;
                    this.dismissAlert = false;
                    setTimeout(() => {
                        this.dismissAlert = true;
                        setTimeout(() => {
                            this.clear();
                        }, 400);
                    }, this.message.timeout);
                }
            }
        });
    }

    clear() {
        this.alertService.clear();
        this.message = null;
    }
}
