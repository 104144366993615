import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IUserGroup } from '../models/accounts';
import { Observable, Observer } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class OrderService {
  baseUrl: string;
  progress!: Observable<any>;
  progressObserver!: Observer<any>;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.API_BASE_URL + '/orders';
    this.progress = Observable.create((observer: any) => {
      this.progressObserver = observer;
    });
  }

  getById = (id: string): Observable<any> =>
    this.http.get(`${this.baseUrl}/${id}`);

  getAll = (queryString?: string): Observable<any> =>
    this.http.get(`${this.baseUrl}`);


  post = (data: any): Observable<any> =>
    this.http.post(`${this.baseUrl}`, data);

  update = (id: string, data: any): Observable<any> =>
    this.http.patch(`${this.baseUrl}/${id}`, data);

  // addEmployeeType = (data: any): Observable<any> =>
  //   this.http.post(`${this.baseUrl}/employee-type`, data);

  delete = (id: string): Observable<any> =>
    this.http.delete(`${this.baseUrl}/${id}`);



}
