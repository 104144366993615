<div class="loader-image-div" *ngIf="showLoading">
  <div class="loader-container">
    <div class="loader"></div>
  </div>
</div>


<header class="py-10 mb-4 bg-gradient-primary-to-secondary">
  <div class="container-xl px-4">
      <div class="text-center">
          <p class="lead mb-0 text-white-50 fs-3">Welcome to</p>
          <h1 class="text-white">INSTAFOLLOWERS</h1>
      </div>
  </div>
</header>

<div class="container-xl px-4 mt-n10">
  <div class="row">
    <div class="col-xl-4 mb-4">
        <!-- Dashboard example card 1-->
        <a class="card lift h-100" href="#!">
            <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="me-3">
                        <h5 class="fs-2">100</h5>
                        <div class="text-muted">Registered Users</div>
                    </div>
                    <img src="./assets/images/users.png" width="40">
                </div>
            </div>
        </a>
    </div>
    <div class="col-xl-4 mb-4">
        <!-- Dashboard example card 2-->
        <a class="card lift h-100" href="#!">
            <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="me-3">
                        <h5 class="fs-2">50</h5>
                        <div class="text-muted">Total Services</div>
                    </div>
                    <img src="./assets/images/self-service.png" width="40">
                </div>
            </div>
        </a>
    </div>
    <div class="col-xl-4 mb-4">
        <!-- Dashboard example card 3-->
        <a class="card lift h-100" href="#!">
            <div class="card-body d-flex justify-content-center flex-column">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="me-3">
                        <h5 class="fs-2">${{wallet?.balance}}</h5>
                        <div class="text-muted">Wallet Balance (smmfollows.com)</div>
                    </div>
                    <img src="./assets/images/wallet.png" width="45">
                </div>
            </div>
        </a>
    </div>
  </div>
</div>


<div class="container px-4 my-4">
  <div class="row">
    <div class="col-md-9">
      <div class="mb-4 card">
        <div class="p-4 card-body">
          <div class="row">
            <div class="col">
              <h5 class="fs-4 fw-semibold card-title">New Orders</h5>
              <h6 class="fw-normal text-disabled mb-4 card-subtitle">{{this.tc.data.length}} new ordes</h6>
            </div>
            <div class="ms-auto col-auto">
              <button type="button" class="btn btn-secondary" [routerLink]="['/orders']">View All Orders</button>
            </div>
          </div>

          <app-custom-table
          [tablePage]="tc"
          [tablePageData]="tc.data"
          [tablePageIndex]="tc.pageIndex"
          [tablePageSize]="tc.pageSize"
          [tablePagesLength]="tc.totalCount"
          [tableDataLoading]="loading"
        ></app-custom-table>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="card">
          <div class="card-body text-center p-4">
              <img class="img-fluid mb-4" src="assets/images/team-spirit.svg" alt="" style="max-width: 16.25rem">
              <h5>Service Access to User</h5>
              <p class="mb-4">create service to recieve orders online.</p>
              <a class="btn btn-primary p-3" href="#!" routerLink="/services-management">Add Service</a>
          </div>
      </div>
    </div>
  </div>

  <!-- <div class="row">
    <div class="card mb-4">
      <div class="card-header">Revenue Summary</div>
      <div class="card-body">
          <div class="chart-area"><div class="chartjs-size-monitor"><div class="chartjs-size-monitor-expand"><div class=""></div></div><div class="chartjs-size-monitor-shrink"><div class=""></div></div></div><canvas id="myAreaChart" width="875" height="240" style="display: block; width: 875px; height: 240px;" class="chartjs-render-monitor"></canvas></div>
      </div>
  </div>
  </div> -->
</div>
