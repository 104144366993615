<div class="table-responsive">


  <table
    mat-table
    [dataSource]="data"
    matSort
    (matSortChange)="tablePage.handleSortChange($event)"
  >

    <ng-container
      *ngFor="let column of tablePage.columns"
      matColumnDef="{{ column.key }}"
    >

      <ng-container *ngIf="column.sortable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
           {{ column.value }}
        </th>
      </ng-container>
      <ng-container *ngIf="!column.sortable">
        <th mat-header-cell *matHeaderCellDef>
          {{ column.value }}
        </th>
      </ng-container>

      <td mat-cell *matCellDef="let member">
        <div class="skeleton skeleton-text skeleton-text__body" *ngIf="tableDataLoading"></div>
        <ng-container *ngIf="column.contentType === 'date'">
          {{ member[column.key] | date: 'MM-dd-yyyy' }}
        </ng-container>
        <ng-container *ngIf="column.contentType === 'link'">
          <a [routerLink]="member[column.key]?.link">{{
            member[column.key]?.content
          }}</a>
        </ng-container>
        <ng-container *ngIf="column.contentType === 'externalLink'">
          <a [href]="member[column.key]?.link" target="_blank" >{{
            member[column.key]?.content
          }}</a>
        </ng-container>
        <ng-container *ngIf="column.contentType === 'text'">
          <span class="text-capitalize" [ngClass]="{'text-success': column.key === 'isActive' && member[column.key] === 'Enabled', 'text-danger': column.key === 'isActive' && member[column.key] === 'Disabled'}">{{ member[column.key] }}</span>
        </ng-container>
        <ng-container *ngIf="column.contentType === 'price'">
          {{ member[column.key]?.toString() | currency:'USD':'symbol'}}
        </ng-container>
        <ng-container *ngIf="column.contentType === 'list'">
          <ul class="array-list">
            <ng-container *ngFor="let value of member[column.key]">
              <li *ngIf="value?.length">{{value}}</li>
            </ng-container>

          </ul>
         </ng-container>
        <ng-container *ngIf="column.contentType === 'array'">
          <ng-container *ngFor="let item of member[column.key]">
            <h6>{{item.label }}</h6>
            {{item.data}}
          </ng-container>
        </ng-container>
        <ng-container *ngIf="column.contentType === 'object'">
          <h6>{{member[column.key]?.label }}</h6>
          {{member[column.key]?.data}}
        </ng-container>
        <ng-container *ngIf="column.contentType === 'image'">
          <img src="{{ member[column.key] }}" class="object-fit-cover" width="40" height="40" alt="">
        </ng-container>
        <ng-container *ngIf="column.contentType === 'html'">
          <div [innerHTML]="member[column.key]" class="p-1"></div>
        </ng-container>
        <ng-container *ngIf="column.contentType === 'linkhtml'">
          <span [innerHTML]="member[column.key].span" class="p-1"></span>
          <a *ngIf="member[column.key].content" [innerHTML]="member[column.key].content" [routerLink]="member[column.key]?.link" class="p-1"></a>
        </ng-container>

        <ng-container *ngIf="column.contentType === 'buttonArray'">
          <button
            *ngFor="let item of member[column.key]"
            [innerHTML]="item?.btnLabel"
            [class]="item?.btnClass"
            [disabled]="item?.isDisabled"
            (click)="item?.clickHandler(member)"
          ></button>
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="hkeys"></tr>
    <tr mat-row *matRowDef="let row; columns: hkeys"></tr>
  </table>
  <!-- <mat-paginator
    [length]="tablePagesLength"
    [pageSize]="tablePageSize"
    [pageSizeOptions]="[10, 25, 50]"
    (page)="handlePageChange($event)"
  ></mat-paginator> -->
</div>
